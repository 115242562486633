document.addEventListener("turbolinks:load", function(){
	
	var tabs = $("#slider-thumbnails > .slider-thumbnail"),
			slides = $("#homepage-slider .slide"),
			navigation_prev = $(".slider-direction-nav .slider-prev"),
			navigation_next = $(".slider-direction-nav .slider-next"),
			interval_id = null,
			duration = 4000; //parseFloat($("#homepage-slider").data("duration-on-seconds")) * 1000
			current_slide = 0,
			move_to_slide = null,
			move_to_next = null,
			move_to_prev = null,
			move_with_time = null,
			stop_video_if_playing = null,
			is_video_playing = false;

	// Navigation helper functions ;)
	move_to_slide = function(index){


		slides.parent().find(".slide.active").removeClass("active");
		$(slides[index]).addClass("active");

		tabs.parent().find(".active").removeClass("active");
		$(tabs[index]).addClass("active");

		current_slide = index;
	};

	move_with_time = function(){
		window.clearInterval(interval_id);
		interval_id = window.setInterval(function(){
			if ( !is_video_playing )
				move_to_next()
		}, duration);
	};

	move_to_next = function(){
		current_slide++;
		current_slide = ( current_slide>=slides.length ) ? 0 : current_slide;

		move_to_slide(current_slide);
	};

	move_to_prev = function(){
		current_slide--;
		current_slide = ( current_slide<0 ) ? slides.length-1 : current_slide;

		move_to_slide(current_slide);
	};

	// Attach to the navigation arrows
	navigation_next.on("click", function(e){
		e.preventDefault();
		move_to_next();
		move_with_time();
	});
	navigation_prev.on("click", function(e){
		e.preventDefault();
		move_to_prev();
		move_with_time();
	});
	
	move_with_time();

	// Lets handle the tabs behavoir
	tabs.on("click", function(e){
		e.preventDefault();

		var me = $(this);

		tabs.parent().find(".active").removeClass("active");
		me.addClass("active");

		move_to_slide(me.index());
		move_with_time();
	});

});